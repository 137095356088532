import '../css/app.css';

import {createApp, h, Plugin} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy';
import Pusher from "pusher-js";
import {createPinia} from "pinia";
import VueCookies from 'vue-cookies'
import * as Sentry from "@sentry/vue";
import {i18nInstance} from "@/i18n";
import clickOutsideDirective from "@/store/hooks/ClickOutsideDirective";
import autolink from "@/directives/autolink";

const pinia = createPinia();

createInertiaApp({
    title: () => i18nInstance.global.t('app.title'),
    // @ts-ignore:next-line
    resolve: (name) => resolvePageComponent(`./pages/${name}.vue`, import.meta.glob('./pages/**/*.vue')),
    // @ts-ignore:next-line
    setup({ el, App, props, plugin }) {
         const app = createApp({render: () => h(App, props)});

        try {
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
                environment: import.meta.env.VITE_APP_ENV,

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,

                // Capture Replay for 10% of all sessions,
                // plus for 100% of sessions with an error
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
                // debug: true,
            });
        } catch (e) {
            console.error(e);
        }

        const meta = document.createElement('meta');
        meta.name = 'naive-ui-style';
        document.head.appendChild(meta);

        return app
            .use(plugin as Plugin)
            .use(i18nInstance)
            .use(ZiggyVue)
            .use(pinia)
            .use(VueCookies)
            .directive('click-outside', clickOutsideDirective)
            .directive('auto-link', autolink)
            .mount(el)
    },
    progress: {
        color: '#4B5563',
    },
}).then();

declare global {
    interface Window {
        Pusher:any;
    }
}
window.Pusher = Pusher;