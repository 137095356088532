import {createI18n} from "vue-i18n";
import en from "./locales/en.json" // <--- add this
import nl from "./locales/nl.json" // <--- add this

export const i18nInstance = createI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    globalInjection: true,
    legacy: false,
    messages: {
        en,
        nl
    },
})