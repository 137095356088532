// directives/autoLink.ts
import {DirectiveBinding} from 'vue';

function autoLink(text: string): string {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlPattern, '<a href="$1" target="_blank" class="text-blue-500 underline">$1</a>');
}

export default {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        el.innerHTML = autoLink(el.innerText);
    },
    updated(el: HTMLElement, binding: DirectiveBinding) {
        el.innerHTML = autoLink(el.innerText);
    }
};